import { defineStore } from 'pinia';
import { IFoodPantry } from '~/composables/types/volunteer/FoodPantry';

export const useFoodPantryStore = defineStore(
  'food-pantry',
  () => {
    const foodPantry = ref<IFoodPantry | null>(null);
    const setFoodPantry = (data: IFoodPantry | null) => {
      foodPantry.value = data;
    };

    const authFormData = ref<any>(null);
    const setAuthFormData = (data: any) => {
      authFormData.value = data;
    };

    const foodConfig = ref<any>(null);
    const setFoodConfig = (data: any) => {
      foodConfig.value = data;
    };

    const selectedAssistanceRequest = ref<any>(null);
    const setSelectedAssistanceRequest = (data: any) => {
      selectedAssistanceRequest.value = data;
    };

    const searchedData = ref<any>(null);
    const startSearch = ref(false);

    const volunteerID = ref<string | null>(null);
    return {
      foodPantry,
      setFoodPantry,
      authFormData,
      setAuthFormData,
      foodConfig,
      setFoodConfig,
      selectedAssistanceRequest,
      setSelectedAssistanceRequest,
      searchedData,
      startSearch,
      volunteerID,
    };
  },
  {
    persist: true,
  }
);
